import React, { Component, lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuidv4 } from 'uuid';
import './App.css';

//import Navbar from './componentes/Navbar';
import ProductTitle from './componentes/Productos/productTitle';
import Carousel, { CarouselItem } from './componentes/Carousel/Carousel';
import AboutBrios from './componentes/About/AboutBrios';
import Footer from './componentes/Footer';
//import ContactoItem from './componentes/Contacto/contactoItem';
//import CarouselBlog, { CarouselItemBlog } from './componentes/BlogCarousel/BlogCarousel';
//import textBanner from './assets/images/text-banner.webp';
//import productosBanner from './assets/images/panes-brios-banner.webp';
//import iconPedido from './assets/images/icon-pedido.webp';


import cereales from './assets/images/brios-cereales-b.webp';
import semillas from './assets/images/brios-semillas-b.webp';
import frutal from './assets/images/brios-frutas-b.webp';
import coconut from './assets/images/brios-coconut-b.webp';

import cerealesLarge from './assets/images/Brios-Cereales-Descripcion-Desktop.webp';
import semillasLarge from './assets/images/Brios-Semillas-Descripcion-Desktop.webp';
import frutalLarge from './assets/images/Brios-Frutal-Descripcion-Desktop.webp';
import coconutLarge from './assets/images/Brios-Coconut-Descripcion-Desktop.webp';

import semillasSmall from './assets/images/Brios-Semillas-Descripcion-Mobile.webp';
import frutalSmall from './assets/images/Brios-Frutal-Descripcion-Mobile.webp';
import CerealesSmall from './assets/images/Brios-Cereales-Descripcion-Mobile.webp';
import coconutSmall from './assets/images/Brios-Coconut-Descripcion-Mobile.webp';


//import geolocalizacion from './assets/images/icon-geolocalizacion.webp';
//import imgBlog from './assets/images/img-blog.webp';
//import address from './assets/images/icon-address.webp';
//import instagram from './assets/images/icon-instagram.webp';
//import facebook from './assets/images/icon-facebook.webp';
//import email from './assets/images/icon-email.webp';
import loading from './assets/images/loading.gif';
//import iconTiendaVirtual from './assets/images/icono-tienda-virtual.webp';



//import { CarouselItem } from './componentes/Carousel/Carousel';
const Navbar = lazy(() => import('./componentes/Navbar'));
//const Carousel = lazy(() => import('./componentes/Carousel/Carousel'));
const ProductModal = lazy(() => import('./componentes/Modal/productModal'));
const PlayerYoutube = lazy(() => import('./componentes/PlayerYoutube/PlayerYoutube'));
const ContentDistribuidores = lazy(() => import('./componentes/Distribuidores/ContentDistribuidores'))

const renderLoader = () => <p>Loading</p>;


// Precios Fecha: 9 Diciembre 2024
//cereales $7.700  -  semillas $8.900  -  frutal $11.800  -  coconut $10.800 - Manzana $ 12.000
const products = [
  {
    id: 2,
    name: 'Brios Frutal',
    imageUrl: frutal,
    imageLarge: frutalLarge,
    imageSmall: frutalSmall,
    description: 'Pan Brios con frutas. Una explosión de sabor y vitaminas.',
    precio: 11800
  },
  {
    id: 4,
    name: 'Brios Coconut',
    imageUrl: coconut,
    imageSmall: coconutSmall,
    imageLarge: coconutLarge,
    description: 'Pan Brios con trozos de Cocada. Dale un delicioso toque dulce-tradicional a tus comidas.',
    precio: 10800
  },
  {
    id: 1,
    name: 'Brios Semillas',
    imageUrl: semillas,
    imageLarge: semillasLarge,
    imageSmall: semillasSmall,
    description: 'Pan Brios con semillas. Rico en fibras y nutrientes esenciales.',
    precio: 8900
  },
  {
    id: 3,
    name: 'Brios Cereales',
    imageUrl: cereales,
    imageSmall: CerealesSmall,
    imageLarge: cerealesLarge,
    description: 'Pan Brios con cereales. Perfecto para un desayuno saludable.',
    precio: 7700
  }
];

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error captured by ErrorBoundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// Función para obtener fbc y fbp
const getFbcFbp = () => {
  const cookies = document.cookie.split(';');
  let fbc = '';
  let fbp = '';
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith('_fbp')) {
      fbp = cookie.split('=')[1];
    } else if (cookie.startsWith('_fbc')) {
      fbc = cookie.split('=')[1];
    }
  }
  return { fbc, fbp };
};

// Obtener fbc y fbp al inicio de la sesión
const { fbc, fbp } = getFbcFbp();
console.log("fbc:", fbc, "fbp:", fbp);


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scroll: false,
      error: false,
      mensajeError: "",
      styleError: "",
      /* ----------- Pixel Original ----------- */
      access_token: "EAAFDiZCpYcz0BALr9oCKrZBCWZCdvEe2I2yZBWEQZCVxtyPb2euXFHJbB1bJ6UTLCfxWMbmfSWFQpEAJrBvnAJ8nHO4QfuysStyoi4lw6OWngkMJq6EDkxJh05uCV43NHtav8Xyrke3dUMsn3FLH2ErQgVDwD95azD5kXDLPXFBzclksPdotcaJKiGH6R5LQZD",
      pixel_id: "170743725217065",
      /* ----------- Pixel Original ----------- */

      /* ----------- Pixel de Pruebas ----------- */
      //access_token: "EAAFDiZCpYcz0BO9WHZB47t637s7Mzo31HrPyqpOo2AxCmEAoXAFXTk0q6p1JZBs31USUjFAT5PSVuKPSrtCAGZANCF9ZCC7AeXqZCKqcMDCEL7cxQ80dutPZBLmRxZCUO27n0czwFs86LIA6mHkFD0UB2vOEdvYP13ciZAUF2WBCjfwZCdMxPTJw4LYuAvb9bH1nNlJAZDZD",
      //pixel_id: "2565479776955371",
      /* ----------- Pixel de Pruebas ----------- */

      ip: "",
      userId: "",
      selectedProduct: null,
      windowWidth: window.innerWidth,
      cel: "3172360243"
    }
    this.sectionRef = React.createRef();

  }

  // Genera un identificador único
  generateUniqueId = () => {
    return '_UserBrios_' + uuidv4();
  }

  // Verifica si el identificador único ya existe, si no, lo genera y lo almacena
  getUserId = () => {
    let userId = localStorage.getItem('userId');
    if (!userId) {
      userId = this.generateUniqueId();
      localStorage.setItem('userId', userId);
    }
    return userId;
  }

  scrollToSection = () => {
    if (this.sectionRef.current) {
      this.sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  componentDidMount() {
    //const pixel_id = '170743725217065';
    const pixel_id = this.state.pixel_id;
    window.addEventListener('resize', this.handleResize);

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    // Verificar si el píxel ya está inicializado
    if (!window.fbPixelInitialized) {
      ReactPixel.init(pixel_id, options);
      window.fbPixelInitialized = true; // Marcar el píxel como inicializado
    }

    this.getData();
    this.preloadImages();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  //creating function to load ip address from the API
  getData = async () => {
    const res = await axios.get('https://api64.ipify.org?format=json')
    const userId = this.getUserId();
    //console.log(res.data);
    //console.log(navigator)
    const ipUser = res.data.ip;
    this.setState({ ip: ipUser, userId: userId });
    this.serverPageView(ipUser, userId);
    console.log("ip: ", this.state.ip, "userId: ", this.state.userId);
  }


  initialError = (data) => {
    this.setState({ error: data, mensajeError: "", styleError: "" })
  }

  // Método para generar un ID único
  //generateId = () => Math.random().toString(36).substr(2, 18);
  generateId = () => uuidv4();

  // Método para enviar el evento de PageView al cliente y servidor
  serverPageView = (ipUser, userId) => {

    const id_event = this.generateId();
    let current_timestamp = Math.floor(new Date() / 1000);
    console.log("pageView: ", id_event, fbc, fbp);

    // Enviar evento de PageView al cliente (navegador)
    ReactPixel.pageView({
      event_time: current_timestamp,
      external_id: userId,
      action_source: "website",
      user_data: {
        client_ip_address: ipUser,
        client_user_agent: window.navigator.userAgent,
        fbc: fbc,
        fbp: fbp
      }
    },
      {
        eventID: id_event
      });

    // Datos del evento para enviar al servidor
    const data = {
      "data": [
        {
          "event_name": "PageView",
          "event_time": current_timestamp,
          "event_id": id_event,
          "action_source": "website",
          "user_data": {
            "client_ip_address": ipUser,
            "client_user_agent": window.navigator.userAgent,
            "fbc": fbc,
            "fbp": fbp,
            "external_id": this.state.userId,
          }
        }
      ]
    };

    // Enviar el evento al servidor de Facebook
    fetch(
      `https://graph.facebook.com/v12.0/${this.state.pixel_id}/events?access_token=${this.state.access_token}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }
    )
      .then(response => response.json())
      .then(response => {
        // Manejo de la respuesta del servidor de Facebook
        console.log(data);
        console.log("Evento enviado con éxito:", response);
      })
      .catch(error => {
        // Manejo de errores
        console.error("Error al enviar el evento:", error);
      });
  }




  preloadImages = () => {
    products.forEach(product => {
      const img = new Image();

      this.state.windowWidth < 800 ?
        img.src = product.imageSmall
        :
        img.src = product.imageLarge

    });
  };


  handleImageClick = (product) => {
    this.setState({ selectedProduct: product });
  }

  handleCloseModal = () => {
    this.setState({ selectedProduct: null });
  }


  render() {

    return this.state.ip === "" ? <div className="loading d-flex justify-content-center align-items-center"><img src={loading} /></div> :
      <ErrorBoundary>
        <Router>
          <Suspense fallback={renderLoader()}>
            <div className="main">
              <Navbar />
            </div>

            <Routes>

              <Route path="/" element={<>
                {this.state.pixel_id != undefined ?
                  <PlayerYoutube
                    fbc={fbc} fbp={fbp}
                    userId={this.state.userId}
                    ip={this.state.ip ? this.state.ip : ""}
                    pixel_id={this.state.pixel_id}
                    access_token={this.state.access_token}
                    scrollToSection={this.scrollToSection}
                  /> : "Loading"}
              </>} />

            </Routes>

            <div ref={this.sectionRef} id="distribuidores" className="b-mt b-pan w-100 mb-5">
              {this.state.pixel_id != undefined ?
                <ContentDistribuidores
                  fbc={fbc} fbp={fbp}
                  userId={this.state.userId}
                  ip={this.state.ip ? this.state.ip : ""}
                  pixel_id={this.state.pixel_id}
                  access_token={this.state.access_token}
                /> : "Loading"}
            </div>

            <div id="productos" className="parallax w-100 pt-5">
              <ProductTitle />
            </div>

            <div className="product bg-white">
              <div className="container">
                <div className="row">
                  <Carousel>
                    {products.map((product) => (
                      <CarouselItem key={product.id}>
                        <div className="d-flex flex-column text-center align-items-center">
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            onClick={() => this.handleImageClick(product)}
                            className="product-image img-fluid mb-3"
                            width="400px" height="500px"
                          />
                          <h4>{product.name}</h4>
                          <h2 className='precio'>$ {product.precio.toLocaleString('es-CO')}</h2>
                          <p className='mensaje mb-3'>Precio sugerido.</p>
                          <button type="button" onClick={() => this.handleImageClick(product)} className="btn btn-primary appbtn px-4 py-2 mb-3">
                            Ver Detalles
                          </button>
                        </div>
                      </CarouselItem>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div id="grupo-brios" className="container border-top">
              <AboutBrios />
            </div>

            <footer id="contacto" className="b-footer w-100 mt-5">
              <Footer
                fbc={fbc} fbp={fbp}
                userId={this.state.userId}
                ip={this.state.ip ? this.state.ip : ""}
                pixel_id={this.state.pixel_id}
                access_token={this.state.access_token}
              />
            </footer>

            {this.state.selectedProduct && (
              <ProductModal
                product={this.state.selectedProduct}
                onClose={this.handleCloseModal}
                access_token={this.state.access_token}
                pixel_id={this.state.pixel_id}
                fbc={fbc}
                fbp={fbp}
                ip={this.state.ip}
                userId={this.state.userId}
              />
            )}

          </Suspense>
        </Router>
      </ErrorBoundary>;
  }
}

export default App;
